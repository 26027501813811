import CapillariesTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-redness/capillaries/CapillariesTemplate';

import { REDNESS_DURATION, CAPILLARY_FACE_LOCATION } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/Redness/Capillaries/CapillariesTemplate'
};

const createStory = props => () => ({
  components: { CapillariesTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <capillaries-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  prominentBloodVessels: 'no',
  gender: 'female',
  firstBloodVesselOccurrance: REDNESS_DURATION.UNDER_A_YEAR,
  bloodVesselLocations: CAPILLARY_FACE_LOCATION.CHIN,
  rednessDetailQuestionsVisible: false
});

export const WithBloodVessels = createStory({
  prominentBloodVessels: 'yes',
  gender: 'female',
  firstBloodVesselOccurrance: REDNESS_DURATION.UNDER_A_YEAR,
  bloodVesselLocations: CAPILLARY_FACE_LOCATION.CHIN,
  rednessDetailQuestionsVisible: true
});
